<div
  class="buttons-container"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
  fxLayoutGap.lt-sm="12px"
>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="dashboard"
    [class.active]="isDashboardActive"
  >
    Dashboard
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="transfer"
    routerLinkActive="active"
  >
    Transfer
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="payments"
    routerLinkActive="active"
  >
    Payments
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="statement"
    routerLinkActive="active"
  >
    Statement
  </button>
</div>
<router-outlet></router-outlet>
<footer app-footer></footer>
