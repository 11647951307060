import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Sections } from '../../dashboard/shared/sections.enum';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ErrorService } from '../../shared/error-dialog/error.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TopUpDialogComponent } from './top-up-dialog/top-up-dialog.component';
import { filter, finalize, first, map, tap } from 'rxjs/operators';
import {
  TopUpResultDialogComponent,
  TopUpResultDialogData,
} from './top-up-dialog/top-up-result-dialog/top-up-result-dialog.component';
import { calculateDateRange } from '../../dashboard/shared/calculateDateRange';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../login/services/auth.service';
import { UserType } from '../../login/models/user-type.enum';
import { InfoService } from '../../shared/info-dialog/info.service';
import { StatementService } from 'src/app/statement/statement.service';
import { StatementItem } from 'src/app/statement/models/statement-item.model';

@Component({
  selector: 'app-iban-account',
  templateUrl: './iban-account.component.html',
  styleUrls: ['./iban-account.component.scss'],
})
export class IbanAccountComponent implements OnInit {
  accountTransactions: StatementItem[] = [];
  isLoadingTransactions: boolean = false;
  isLoadingHeader: boolean = false;
  isGeneratingCertificate: boolean = false;
  Sections = Sections;
  availableBalance?: number;
  balance?: number;
  accountNumber?: string;
  isCorporateUser: boolean = true;

  $breakpointObserver?: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private httpClient: HttpClient,
    private authService: AuthService,
    private infoService: InfoService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.authService.getAuthenticatedUser().subscribe((user) => {
      this.isCorporateUser = user?.type === UserType.CORPORATE;
    });

    this.isLoadingTransactions = true;
    this.isLoadingHeader = true;
    const dateRange = calculateDateRange();
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        if (accounts.iban) {
          this.accountNumber = accounts.iban.iban;
          this.availableBalance = accounts.iban.availableBalance;
          this.balance = accounts.iban.balance;
        }
        this.isLoadingHeader = false;
        this.isLoadingTransactions = false;
        // get latest transactions of IBAN
        this.statementService
          .getStatement({
            accountCode: accounts?.iban?.code,
            dtStartDate: dateRange.past,
            dtEndDate: dateRange.present,
            page: 0,
            limit: 8,
            orderAscending: false,
            currency: accounts?.iban?.ccy,
          })
          .subscribe(
            (statements) => {
              this.accountTransactions = statements.items;
              this.isLoadingTransactions = false;
            },
            () => {
              this.errorService.showErrorDialog();
              this.isLoadingTransactions = false;
            }
          );
      },
      () => {
        this.isLoadingHeader = false;
        this.isLoadingTransactions = false;
        this.errorService.showErrorDialog();
      }
    );

    // opens dialog with the result of 'top up' when needed
    this.route.queryParams.pipe(first()).subscribe((params) => {
      if (params.topUpSuccess === 'true' || params.topUpSuccess === 'false') {
        this.dialog
          .open<TopUpResultDialogComponent, TopUpResultDialogData, boolean>(
            TopUpResultDialogComponent,
            {
              panelClass: 'dialog-with-close-button',
              width: '400px',
              data: {
                isSuccess: params.topUpSuccess === 'true',
              },
            }
          )
          .beforeClosed()
          .pipe(
            tap(() => this.router.navigate([], { relativeTo: this.route })), // cleans url after closing dialog
            filter((tryAgain) => tryAgain === true)
          )
          .subscribe(() => this.topUpWithCard());
      }
    });
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(
          (state) =>
            state.breakpoints[Breakpoints.XSmall] ||
            state.breakpoints[Breakpoints.Small]
        )
      );
  }

  navigateToStatements() {
    this.router.navigateByUrl('/statement');
  }

  generateCertificate(): void {
    const fileName = 'IBAN certificate.pdf';
    const backendQueryParams = new URLSearchParams({
      path: 'sub-dashboard/iban-certificate',
      fileName,
      landscape: 'false',
      footerTemplate: '<br/>', // used for overriding default footer
    });
    const url = `${
      environment.BACKEND_URL
    }/pdf?${backendQueryParams.toString()}`;
    this.isGeneratingCertificate = true;
    this.httpClient
      .get(url, { observe: 'body', responseType: 'arraybuffer' })
      .pipe(finalize(() => (this.isGeneratingCertificate = false)))
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        () => this.errorService.showErrorDialog()
      );
  }

  topUpWithCard(): void {
    this.dialog.open<TopUpDialogComponent>(TopUpDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '700px',
      minHeight: '300px',
    });
  }

  showInfo(): void {
    let title: string = 'How to top-up your IBAN';
    let message1: string =
      'You can top-up your IBAN with a Debit card or by initiating EUR to EUR transfer (SEPA transfer) from your regular bank account.';
    let message2: string =
      'For incoming payments, please make sure that the sender enters your name exactly as registered in your eCREDO Account.';
    this.infoService.showInfoDialog(title, message1, '', message2);
  }
}
