import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { StatementItem } from '../models/statement-item.model';
import { StatementService } from '../statement.service';

@Component({
  selector: 'app-statement-pdf',
  templateUrl: './statement-pdf.component.html',
  styleUrls: ['./statement-pdf.component.scss'],
})
export class StatementPdfComponent implements OnInit {
  dateFrom?: string;
  dateTo?: string;
  data: StatementItem[] = [];
  today = new Date();
  accountName?: string;
  availableBalance?: number;
  bookBalance?: number;
  currency?: string;

  allColumns: string[] = [
    'createdAt',
    'type',
    'toAccountName',
    'transactionCodeName',
    'fromAmount',
    'toAmount',
    'currentBalance',
    'transactionRefId',
  ];

  displayedColumns = this.allColumns;

  constructor(
    private route: ActivatedRoute,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        switchMap(
          ({
            accountCode,
            dtStartDate,
            dtEndDate,
            page,
            limit,
            orderField,
            orderAscending,
            accountName,
            availBal,
            bookBal,
            ccy,
          }) => {
            this.dateFrom = dtStartDate;
            this.dateTo = dtEndDate;
            this.accountName = accountName;
            this.availableBalance = availBal;
            this.bookBalance = bookBal;
            this.currency = ccy;

            return this.statementService.getStatement({
              accountCode,
              dtStartDate,
              dtEndDate,
              page,
              limit,
              orderField,
              orderAscending: orderAscending === 'true',
              currency: ccy,
            });
          }
        )
      )
      .subscribe((res) => {
        this.data = res.items;
      });
  }
}
