import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { AuthService } from '../../services/auth.service';
import {
  COUNTDOWN_IN_SECONDS,
  NUMBER_OF_ALLOWED_SMS_RESENDS,
} from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isAutomaticLogout = false;
  isWrongPassword = false;
  isPasswordReset = false;
  isEmailConfirmationProblem = false;
  isVerificationProblem = false;
  isEmailChangeSuccess = false;
  isEmailChangeProblem = false;
  isLoading = false;
  isSendingAgain = false;
  isIosInfo = false;

  hidePassword = true;
  errorStatus: number | null | undefined;
  otpErrorStatus: number | null | undefined;
  requestOtp: boolean = false;
  smsSentAgain: boolean = false;
  isSendAgainDisabled = false;
  countdown: number = COUNTDOWN_IN_SECONDS;
  numOfResendsAllowed: number = NUMBER_OF_ALLOWED_SMS_RESENDS;
  noMoreResendsAllowed: boolean = false;

  loginForm = this.fb.group({
    login: [localStorage.getItem('login'), Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
    rememberMe: false,
    rememberDevice: false,
  });

  @HostListener('window:keyup.Enter')
  onLoginClick(): void {
    this.requestOtp ? this.logIn() : this.checkOtpIfRequired();
  }

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private router: Router
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    this.isAutomaticLogout = state?.isAutomaticLogout;
    this.isWrongPassword = state?.isWrongPassword;
    this.isPasswordReset = state?.isPasswordReset;
    this.isEmailConfirmationProblem = state?.isEmailConfirmationProblem;
    this.isVerificationProblem = state?.isVerificationProblem;
    this.isEmailChangeSuccess = state?.isEmailChangeSuccess;
    this.isEmailChangeProblem = state?.isEmailChangeProblem;
    const isIos = /iphone|ipad|ipod/.test(
      window.navigator.userAgent.toLowerCase()
    );
    const isInStandaloneMode: boolean =
      'standalone' in window.navigator && (window.navigator as any).standalone;
    this.isIosInfo =
      isIos &&
      !isInStandaloneMode &&
      !this.isAutomaticLogout &&
      !this.isWrongPassword &&
      !this.isPasswordReset &&
      !this.isEmailConfirmationProblem &&
      !this.isVerificationProblem &&
      !this.isEmailChangeSuccess &&
      !this.isEmailChangeProblem;
  }

  ngOnInit(): void {
    this.otpControl.disable();
  }

  async checkOtpIfRequired(): Promise<void> {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.errorStatus = null;
      (
        await this.authService.checkOtpIfRequired(this.login, this.password)
      ).subscribe(
        (resp) => {
          // otp is not required for some user states
          if (resp.otpRequired) {
            this.requestOtp = true;
            this.otpControl.enable();
            this.isLoading = false;
          } else {
            this.requestOtp = false;
            this.logIn();
          }
        },
        (err) => {
          // handle wrong credentials
          this.isLoading = false;
          if (err.status === 400 || err.status === 403) {
            this.errorStatus = err.status;
          } else {
            this.errorService.showErrorDialog();
          }
        }
      );
    }
  }

  async logIn(): Promise<void> {
    if (this.loginForm.valid && this.otpErrorStatus !== 403) {
      this.isLoading = true;
      this.errorStatus = null;
      (
        await this.authService.logIn(
          this.login,
          this.password,
          this.otp,
          this.rememberDevice
        )
      ).subscribe(
        () => {
          this.isLoading = false;
          if (this.rememberMe) {
            localStorage.setItem('login', this.login);
          }
          this.router.navigate(['/']);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          if (this.requestOtp) {
            // if otp is required, handle wrong otp
            if (err.status === 400) {
              this.otpControl?.setErrors({ wrongCode: true }); // invalid otp
            } else if (err.status === 403) {
              this.otpErrorStatus = err.status; // too many failed otp attempts
            } else {
              this.errorService.showErrorDialog();
            }
          } else {
            // if otp is not required, handle wrong credentials
            if (err.status === 400 || err.status === 403) {
              this.errorStatus = err.status;
            } else {
              this.errorService.showErrorDialog();
            }
          }
        }
      );
    }
  }

  async sendSms(): Promise<void> {
    if (this.numOfResendsAllowed > 0) {
      this.startCountdown();
      this.isSendingAgain = true;
      (
        await this.authService.checkOtpIfRequired(this.login, this.password)
      ).subscribe(
        () => {
          this.smsSentAgain = true;
          this.isSendingAgain = false;
          this.numOfResendsAllowed -= 1;
        },
        () => {
          this.isSendingAgain = false;
          this.numOfResendsAllowed -= 1;
          this.errorService.showErrorDialog();
        }
      );
    } else {
      this.noMoreResendsAllowed = true;
    }
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  public get login(): string {
    return this.loginForm.get('login')?.value;
  }

  public get password(): string {
    return this.loginForm.get('password')?.value;
  }

  public get rememberMe(): string {
    return this.loginForm.get('rememberMe')?.value;
  }

  public get rememberDevice(): boolean {
    return this.loginForm.get('rememberDevice')?.value;
  }

  public get otp(): string {
    return this.loginForm.get('otp')?.value;
  }

  get otpControl(): AbstractControl {
    return this.loginForm.get('otp')!;
  }
}
