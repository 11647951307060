<div [formGroup]="filtersGroup">
  <div class="top">
    <div
      fxLayout="row wrap-reverse"
      fxLayoutAlign="start center"
      fxLayoutGap="20px grid"
    >
      <div fxFlex.lt-sm="100">
        <div
          fxLayout
          class="account-selector"
          [class.open]="fromTrigger.menuOpen"
          *ngIf="account"
        >
          <app-account-list-item
            [account]="account"
            [currency]="account.ccy"
          ></app-account-list-item>
          <div
            class="right-section"
            fxLayoutAlign="center center"
            [matMenuTriggerFor]="fromMenu"
            #fromTrigger="matMenuTrigger"
          >
            <mat-icon svgIcon="chevron-contracted"></mat-icon>
          </div>
        </div>

        <!-- FROM MENU -->
        <mat-menu
          #fromMenu="matMenu"
          xPosition="before"
          class="g-accounts-selector-menu"
        >
          <div
            *ngFor="let account of fromAccountsList"
            class="menu-item"
            (click)="selectAccount(account)"
          >
            <app-account-list-item
              [account]="account"
              [currency]="account.ccy"
              [isSmallVersion]="true"
            ></app-account-list-item>
          </div>
        </mat-menu>
      </div>
      <div
        fxLayout
        fxLayoutGap="20px"
        fxLayoutGap.lt-md="10px"
        class="dates-container"
      >
        <div fxLayout="column" class="date-range">
          <label class="white">Select a date range</label>
          <mat-form-field appearance="outline">
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="startDate"
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="endDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <button
          fxHide.gt-sm
          mat-stroked-button
          color="accent"
          class="pdf-button"
          (click)="onGenerateCsv()"
          [appButtonLoader]="isGeneratingCsv"
        >
          <mat-icon class="material-icons-outlined">download</mat-icon>
          CSV
        </button>
        <button
          fxHide.gt-sm
          mat-stroked-button
          color="accent"
          class="pdf-button"
          (click)="onGeneratePdf()"
          [appButtonLoader]="isGeneratingPdf"
        >
          <mat-icon class="material-icons-outlined">download</mat-icon>
          PDF
        </button>
        <div fxLayout fxLayoutGap="20px" fxHide.lt-md>
          <button
            mat-flat-button
            class="g-medium"
            (click)="getLastMonths(1)"
            color="accent"
          >
            Last 30 days
          </button>
          <button
            mat-flat-button
            class="g-medium"
            (click)="getLastMonths(3)"
            color="accent"
          >
            Last 90 days
          </button>
          <button
            mat-flat-button
            class="g-medium"
            (click)="getLastMonths(6)"
            color="accent"
          >
            Last 180 days
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-card>
    <div
      fxHide.lt-md
      fxLayout="row wrap"
      fxLayoutAlign="end"
      fxLayoutGap="30px"
      class="card-top"
    >
      <div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="onGenerateCsv()"
          [appButtonLoader]="isGeneratingCsv"
        >
          Download CSV
        </button>
      </div>
      <div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="onGeneratePdf()"
          [appButtonLoader]="isGeneratingPdf"
        >
          Download PDF
        </button>
      </div>
    </div>

    <div
      class="table-container"
      [class.hidden]="
        !isLoading &&
        (dataSource.isLoadingObs | async) !== true &&
        (dataSource.lengthSubjectObs | async) === 0
      "
    >
      <div
        class="spinner-container"
        *ngIf="isLoading || (dataSource.isLoadingObs | async)"
      >
        <app-big-loader></app-big-loader>
      </div>
      <table
        mat-table
        [dataSource]="dataSource"
        [fixedLayout]="true"
        matSort
        matSortStart="desc"
        matSortDisableClear
        multiTemplateDataRows
      >
        <!-- To Account Column -->
        <ng-container matColumnDef="toAccountName">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="toAccountName">From/To</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>{{ row?.toAccountName }}</div>
          </td>
        </ng-container>

        <!-- From Amount Column -->
        <ng-container matColumnDef="fromAmount">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="fromAmount">Amount</div>
          </th>
          <td mat-cell *matCellDef="let row" class="amount">
            <div [ngClass]="row?.typeName === 'Credit' ? 'credit' : 'debit'">
              <ng-container *ngIf="row?.typeName === 'Credit'">+</ng-container
              >{{ row?.fromAmount | amount }} {{ row?.fromCCY }}
            </div>
          </td>
        </ng-container>

        <!-- To Amount Column -->
        <ng-container matColumnDef="toAmount">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="toAmount">Transaction Amount</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>
              {{ row?.typeName === 'Credit' ? '+' : '-'
              }}{{ row?.toAmount | amount }} {{ row?.toCCY }}
            </div>
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="type">Type</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>
              {{ row?.typeName }}
            </div>
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="transactionCodeName">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="transactionCodeName">Description</div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.transactionCodeName }}
          </td>
        </ng-container>

        <!-- Current Balance Column -->
        <ng-container matColumnDef="currentBalance">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="currentBalance">Balance</div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.currentBalance | amount }} {{ row?.currentBalanceCcy }}
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef width="10%">
            <div mat-sort-header="createdAt">Date</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>{{ row.createdAt | date: 'shortDate' }}</div>
          </td>
        </ng-container>

        <!-- Reference Column -->
        <ng-container matColumnDef="transactionRefId">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header="transactionRefId">Reference No.</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>{{ row?.transactionRefId }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" colspan="6">
            <div class="spinner-container" *ngIf="isLoadingDetails">
              <app-big-loader></app-big-loader>
            </div>
            <div
              *ngIf="!transactionDetails && !isLoadingDetails"
              class="no-details"
            >
              No transaction details available
            </div>
            <div
              fxLayout
              fxLayout.lt-md="column"
              class="details-container"
              *ngIf="account && transactionDetails"
            >
              <!-- LEFT COLUMN -->
              <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Status</div>
                  <div fxFlex="1 1 100">
                    <span class="state">
                      {{ transactionDetails?.statusName }}</span
                    >
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Payment Type</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.typeName }}
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Amount</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.amountOrder | amount }}
                    {{ transactionDetails?.currencyOrder }}
                  </div>
                </div>
                <!-- show tx amount if ordering and beneficiary currencies are not the same -->
                <div
                  fxLayout="row"
                  fxLayoutGap="20px"
                  *ngIf="
                    transactionDetails?.currencyOrder !==
                    transactionDetails?.currencyBen
                  "
                >
                  <div fxFlex="0 0 168px">Transaction Amount</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.amountBen | amount }}
                    {{ transactionDetails?.currencyBen }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Fees</div>
                  <div fxFlex="1 1 100">
                    {{
                      transactionDetails?.fee
                        ? (transactionDetails?.fee | amount) + ' EUR'
                        : '-'
                    }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Charges</div>
                  <div fxFlex="1 1 100">
                    {{
                      transactionDetails?.charges
                        ? transactionDetails?.charges?.toUpperCase()
                        : '-'
                    }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Value Date</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.valueDate | date: 'shortDate' }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Description for Payer</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.orderDescription }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Description for Beneficiary</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.benDescription }}
                  </div>
                </div>
              </div>
              <br />
              <div class="vertical-line"></div>

              <!-- RIGHT COLUMN -->
              <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
                <!-- ORDERING -->
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Ordering Customer</div>
                  <div fxFlex="1 1 100">
                    <span>{{ transactionDetails?.accountOrder }}</span> <br />
                    <span>{{ transactionDetails?.orderingName }}</span> <br />
                    <span>{{ transactionDetails?.orderAddress }}</span> <br />
                    <span>{{ transactionDetails?.orderPostCode }}</span
                    >,
                    <span>{{ transactionDetails?.orderCity }}</span>
                    <br />
                    <span>{{
                      transactionDetails?.orderCountry | titlecase
                    }}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Ordering Bank</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.bankBICOrder }} <br />
                    {{ transactionDetails?.bankBICOrderName }} <br />
                    {{ transactionDetails?.bankBICOrderAddress }}
                  </div>
                </div>

                <!-- BENEFICIARY -->
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Beneficiary</div>
                  <div fxFlex="1 1 100">
                    <span>{{ transactionDetails?.accountBen }}</span> <br />
                    <span>{{ transactionDetails?.beneficiaryName }}</span>
                    <br />
                    <span>{{ transactionDetails?.benAddress }}</span> <br />
                    <span>{{ transactionDetails?.benPostCode }}</span
                    >,
                    <span>{{ transactionDetails?.benCity }}</span>
                    <br />
                    <span>{{
                      transactionDetails?.benCountry | titlecase
                    }}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <div fxFlex="0 0 168px">Beneficiary Bank</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.bankBICBen }} <br />
                    {{ transactionDetails?.bankBICBenName }} <br />
                    {{ transactionDetails?.bankBICBenAddress }}
                  </div>
                </div>

                <!-- INTERMEDIARY BANK -->
                <div
                  fxLayout="row"
                  fxLayoutGap="20px"
                  *ngIf="
                    transactionDetails?.intermediaryBankBIC ||
                    transactionDetails?.intermediaryBankName ||
                    transactionDetails?.intermediaryBankAddress
                  "
                >
                  <div fxFlex="0 0 168px">Intermediary Bank</div>
                  <div fxFlex="1 1 100">
                    {{ transactionDetails?.intermediaryBankBIC }} <br />
                    {{ transactionDetails?.intermediaryBankName }} <br />
                    {{ transactionDetails?.intermediaryBankAddress }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <button
              *ngIf="transactionDetails"
              mat-stroked-button
              class="print-details"
              (click)="onGenerateDetailsPdf(row)"
              [appButtonLoader]="isGeneratingDetailsPdf"
            >
              <mat-icon fontSet="material-icons-outlined" color="accent"
                >description</mat-icon
              >
              <span>Download the transaction details</span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          matRipple
          class="element-row"
          [class.expandable]="true"
          [ngClass]="{ active: expandedElement === row }"
          [class.expanded]="expandedElement === row"
          (click)="onClickExpand(row)"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="details-row"
          [class.collapsed]="expandedElement !== row"
          style="overflow: hidden"
        ></tr>
      </table>
      <mat-paginator
        [length]="dataSource.getLength() | async"
        [pageSize]="20"
        [pageSizeOptions]="[20, 50, 100]"
      ></mat-paginator>
    </div>

    <div
      class="no-data-row"
      fxLayoutAlign="center center"
      *ngIf="
        !isLoading &&
        (dataSource.isLoadingObs | async) !== true &&
        (dataSource.lengthSubjectObs | async) === 0
      "
    >
      <span>No results </span>
    </div>
  </mat-card>
</div>
