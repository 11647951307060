import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../shared/error-dialog/error.service';
import { AccountDetails } from '../dashboard/models/account-details.model';
import { StatementItem } from './models/statement-item.model';
import { StatementSearch } from './models/statement-search.model';
import { StatementDataSource } from './statement.datasource';
import { StatementService } from './statement.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { PaymentItem } from './models/payment-item.model';

@Component({
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
})
export class StatementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  accounts: AccountDetails[] = [];
  accountOwnerGuid!: string;
  isLoading: boolean = true;
  isLoadingDetails: boolean = false;
  isGeneratingCsv: boolean = false;
  isGeneratingPdf: boolean = false;
  isGeneratingDetailsPdf: boolean = false;

  transactionDetails?: PaymentItem;

  filtersGroup!: FormGroup;

  dataSource!: StatementDataSource;
  displayedColumns: string[] = [
    'createdAt',
    'type',
    'toAccountName',
    'transactionCodeName',
    'fromAmount',
    'toAmount',
    'currentBalance',
    'transactionRefId',
  ];

  account?: AccountDetails;
  expandedElement?: StatementItem;

  private formSub: Subscription | undefined;
  private sortSub: Subscription | undefined;
  private mergeSub: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private statementService: StatementService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.dataSource = new StatementDataSource(
      this.statementService,
      this.errorService
    );
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    const endDate = new Date();
    this.filtersGroup = this.fb.group({
      startDate: startDate.toISOString().substring(0, 10),
      endDate: endDate.toISOString().substring(0, 10),
    });
  }

  ngAfterViewInit(): void {
    this.getInitialData();
    // listens for filters values
    this.formSub = merge(
      this.startDateControl.valueChanges,
      this.endDateControl.valueChanges
    ).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadPage();
    });

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPage()))
      .subscribe();
  }

  private getInitialData(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        this.accounts = [accounts.iban, ...accounts.cards, ...accounts.wallets];
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        if (accounts.iban) {
          this.selectAccount(accounts.iban);
        }
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  selectAccount(account: AccountDetails) {
    this.account = account;
    this.paginator.firstPage();
    this.loadPage();
  }

  loadPage(): void {
    if (!this.account) {
      this.dataSource.clear();
      return;
    }

    if (this.startDateControl.value && this.endDateControl.value) {
      const params: StatementSearch = {
        ...this.generateStatementsReqParams(),
        page: this.paginator.pageIndex,
        limit: this.paginator.pageSize,
      };
      this.dataSource.clear();
      this.dataSource.loadStatements(params);
    }
  }

  getLastMonths(months: number) {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - months);
    const endDate = new Date();
    this.startDateControl.setValue(startDate.toISOString().substring(0, 10));
    this.endDateControl.setValue(endDate.toISOString().substring(0, 10));
  }

  onClickExpand(row: StatementItem) {
    if (row === this.expandedElement) {
      this.expandedElement = undefined;
    } else {
      this.transactionDetails = undefined;
      this.isLoadingDetails = true;
      this.statementService
        .getPaymentDetails({
          transactionReference: row?.transactionRefId,
          accountOwnerGuid: this.accountOwnerGuid,
        })
        .pipe(finalize(() => (this.isLoadingDetails = false)))
        .subscribe(
          (t) => {
            this.transactionDetails = t;
          },
          () => this.errorService.showErrorDialog()
        );
      this.expandedElement = row;
    }
  }

  // print all transactions between dates in csv and pdf statements
  private generateStatementsReqParams(): StatementSearch {
    const params: StatementSearch = {
      accountCode: this.account?.code,
      dtStartDate:
        typeof this.startDateControl.value === 'string'
          ? this.startDateControl.value
          : DateTime.fromISO(this.startDateControl.value).toFormat(
              'yyyy-MM-dd'
            ),
      dtEndDate:
        typeof this.endDateControl.value === 'string'
          ? this.endDateControl.value
          : DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd'),
      page: 0,
      limit: this.dataSource.getCount(),
      orderField: this.sort.active,
      orderAscending: this.sort.direction === 'asc',
      currency: this.account?.ccy,
    };
    return params;
  }

  onGeneratePdf() {
    // prepares frontend path with params (filters out undefined params)
    if (this.startDateControl.value && this.endDateControl.value) {
      const frontendPath = Object.entries(this.generateStatementsReqParams())
        .filter(([key, value]) => value !== undefined)
        .reduce(
          (prev, [key, value]) => `${prev}&${key}=${value}`,
          'statement-pdf?'
        );

      const fileName = 'statements.pdf';
      const backendQueryParams = new URLSearchParams({
        path: `${frontendPath}&accountName=${this.account?.name}&availBal=${this.account?.availableBalance}&bookBal=${this.account?.balance}&ccy=${this.account?.ccy}`,
        fileName: fileName,
        landscape: 'true',
      });
      const url = `${
        environment.BACKEND_URL
      }/pdf?${backendQueryParams.toString()}`;
      this.isGeneratingPdf = true;
      this.httpClient
        .get(url, { observe: 'body', responseType: 'arraybuffer' })
        .pipe(finalize(() => (this.isGeneratingPdf = false)))
        .subscribe(
          (buffer) => {
            var url = window.URL.createObjectURL(new Blob([buffer]));
            var anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
          },
          () => this.errorService.showErrorDialog()
        );
    }
  }

  onGenerateDetailsPdf(row: StatementItem) {
    const fileName = 'statement-details.pdf';
    const frontendQueryParams = new URLSearchParams({
      transactionReference: row.transactionRefId,
      accountOwnerGuid: this.accountOwnerGuid,
    });
    const backendQueryParams = new URLSearchParams({
      path: 'statement-details-pdf?' + frontendQueryParams.toString(),
      fileName,
      landscape: 'false',
      footerMarginHeight: '90px',
      footerTemplate: `
<div style="margin-left:32px;margin-right:30px;width:100%">
  <div style="border-bottom:2px solid #ddd;margin-bottom:8px"></div>
  <div style="font-size:8px">
    <div style="display:inline-block;min-width:35%;padding-right:10px">
      <span style="font-weight:700">eCREDO Limited</span>
    </div>
    <div style="display:inline-block">
      <span>Service Line: 7000 7021,</span><br />
      <span>International Calls: +357 25 508500 </span><br />
      <span>E-mail: info@ecredo.com</span>
    </div>
  </div>
  <div style="font-size:10px;margin-left:auto;font-weight:700;text-align:right;margin-top:0">
    <div>Page <span class="pageNumber"></span> of <span class="totalPages"></span></div>
  </div>
</div>
      `,
      scale: '0.8',
    });
    const url = `${
      environment.BACKEND_URL
    }/pdf?${backendQueryParams.toString()}`;
    this.isGeneratingDetailsPdf = true;
    this.httpClient
      .get(url, { observe: 'body', responseType: 'arraybuffer' })
      .pipe(finalize(() => (this.isGeneratingDetailsPdf = false)))
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        () => this.errorService.showErrorDialog()
      );
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  onGenerateCsv() {
    if (!this.account) {
      return;
    }
    if (this.startDateControl.value && this.endDateControl.value) {
      const params = this.generateStatementsReqParams();
      this.isGeneratingCsv = true;
      this.statementService
        .getStatementsCsv(params)
        .pipe(finalize(() => (this.isGeneratingCsv = false)))
        .subscribe(
          (csv) => {
            var element = document.createElement('a');
            element.setAttribute(
              'href',
              'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
            );
            element.setAttribute('download', 'statements.csv');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          },
          () => this.errorService.showErrorDialog()
        );
    }
  }

  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }

  get fromAccountsList(): AccountDetails[] {
    return this.accounts.filter((acc) => acc !== this.account);
  }
}
