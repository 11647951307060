<div fxLayout fxLayoutAlign="center end">
  <div class="main-container cursor" (click)="redirectTo()" fxLayout>
    <div class="left-section" fxLayout="column">
      <div fxLayout fxLayoutAlign="space-between">
        <div fxLayout fxLayoutAlign="center start">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [svgIcon]="svgIconName"></mat-icon>
            <div class="name">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span>{{ name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="value text-overflow" fxLayoutAlign="center center">
          <app-small-loader *ngIf="isLoadingIBAN"> </app-small-loader>
          <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="0px">
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              *ngIf="
                !isLoadingIBAN &&
                section === Sections.IBAN &&
                (availableBalance || availableBalance === 0)
              "
            >
              <span style="font-size: 15px">
                {{ availableBalance | amount }} EUR
              </span>
              <div style="font-size: 10px">Available balance</div>
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              *ngIf="
                !isLoadingIBAN &&
                section === Sections.IBAN &&
                (balance || balance === 0)
              "
            >
              <span style="font-size: 15px"> {{ balance | amount }} EUR </span>
              <div style="font-size: 10px">Book balance</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-section">
        <div class="iban-number" *ngIf="section === Sections.IBAN">
          <div *ngIf="!isLoadingIBAN && accountNumber" class="text-overflow">
            <img
              src="assets/img/copy.svg"
              alt="copy"
              width="14"
              height="14"
              class="cursor"
              [cdkCopyToClipboard]="accountNumber"
            />
            <span class="account-number">{{ convertIban(accountNumber) }}</span>
          </div>
        </div>
        <div *ngIf="section === Sections.WALLET">
          <div fxLayout="row" fxLayoutAlign="start center" class="balances">
            <mat-icon class="material-icons-outlined balance-header-icon"
              >account_balance_wallet</mat-icon
            >
            <span class="balance-header">Balances</span>
          </div>
          <br />
        </div>
      </div>
    </div>
    <div
      class="right-section"
      fxLayoutAlign="center center"
      *ngIf="isChevron && section === Sections.IBAN"
    >
      <mat-icon svgIcon="chevron-contracted"></mat-icon>
    </div>
  </div>
</div>
