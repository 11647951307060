import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { StatementService } from '../statement.service';
import { PaymentItem } from '../models/payment-item.model';

@Component({
  selector: 'app-statement-details-pdf',
  templateUrl: './statement-details-pdf.component.html',
  styleUrls: ['./statement-details-pdf.component.scss'],
})
export class StatementDetailsPdfComponent implements OnInit {
  transactionDetails?: PaymentItem;
  today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        switchMap(({ transactionReference, accountOwnerGuid }) => {
          return this.statementService.getPaymentDetails({
            transactionReference: transactionReference,
            accountOwnerGuid: accountOwnerGuid,
          });
        })
      )
      .subscribe((t) => {
        this.transactionDetails = t;
      });
  }
}
