<mat-card>
  <div class="main-container">
    <div fxLayout="column" class="header-container">
      <div fxLayout fxLayoutAlign="space-between center">
        <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
        <h1>Transfer details</h1>
      </div>
      <div fxFlexAlign="end" class="date">
        <span>{{ today | date: 'shortDate' }}</span>
      </div>
      <div class="info"><span class="bold">Customer copy</span></div>
      <div>
        <span
          >Please note that we have executed the following payment according to
          your instructions</span
        >
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between none">
      <!-- LEFT COLUMN -->
      <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Reference number</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.transactionReference }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Status</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.statusName }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Payment Type</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.typeName }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Amount</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.amountOrder | amount }}
            {{ transactionDetails?.currencyOrder }}
          </div>
        </div>
        <!-- show tx amount if ordering and beneficiary currencies are not the same -->
        <div
          fxLayout="row"
          fxLayoutGap="20px"
          *ngIf="
            transactionDetails?.currencyOrder !==
            transactionDetails?.currencyBen
          "
        >
          <div fxFlex="0 0 168px">Transaction Amount</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.amountBen | amount }}
            {{ transactionDetails?.currencyBen }}
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Fees</div>
          <div fxFlex="1 1 100">
            {{
              transactionDetails?.fee
                ? (transactionDetails?.fee | amount) + ' EUR'
                : '-'
            }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Charges</div>
          <div fxFlex="1 1 100">
            {{
              transactionDetails?.charges
                ? transactionDetails?.charges?.toUpperCase()
                : '-'
            }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Value Date</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.valueDate | date: 'shortDate' }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Description for Payer</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.orderDescription }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Description for Beneficiary</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.benDescription }}
          </div>
        </div>
      </div>
      <br />
      <div class="vertical-line"></div>

      <!-- RIGHT COLUMN -->
      <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
        <!-- ORDERING -->
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Ordering Customer</div>
          <div fxFlex="1 1 100">
            <span>{{ transactionDetails?.accountOrder }}</span> <br />
            <span>{{ transactionDetails?.orderingName }}</span> <br />
            <span>{{ transactionDetails?.orderAddress }}</span> <br />
            <span>{{ transactionDetails?.orderPostCode }}</span
            >,
            <span>{{ transactionDetails?.orderCity }}</span>
            <br />
            <span>{{ transactionDetails?.orderCountry | titlecase }}</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Ordering Bank</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.bankBICOrder }} <br />
            {{ transactionDetails?.bankBICOrderName }} <br />
            {{ transactionDetails?.bankBICOrderAddress }}
          </div>
        </div>

        <!-- BENEFICIARY -->
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Beneficiary</div>
          <div fxFlex="1 1 100">
            <span>{{ transactionDetails?.accountBen }}</span> <br />
            <span>{{ transactionDetails?.beneficiaryName }}</span>
            <br />
            <span>{{ transactionDetails?.benAddress }}</span> <br />
            <span>{{ transactionDetails?.benPostCode }}</span
            >,
            <span>{{ transactionDetails?.benCity }}</span>
            <br />
            <span>{{ transactionDetails?.benCountry | titlecase }}</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Beneficiary Bank</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.bankBICBen }} <br />
            {{ transactionDetails?.bankBICBenName }} <br />
            {{ transactionDetails?.bankBICBenAddress }}
          </div>
        </div>

        <!-- INTERMEDIARY BANK -->
        <div
          fxLayout="row"
          fxLayoutGap="20px"
          *ngIf="
            transactionDetails?.intermediaryBankBIC ||
            transactionDetails?.intermediaryBankName ||
            transactionDetails?.intermediaryBankAddress
          "
        >
          <div fxFlex="0 0 168px">Intermediary Bank</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.intermediaryBankBIC }} <br />
            {{ transactionDetails?.intermediaryBankName }} <br />
            {{ transactionDetails?.intermediaryBankAddress }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div fxLayout="row" fxLayoutAlign="space-between none">
      <div>Email: support@ecredo.com</div>
      <div>THIS ADVICE REQUIRES NO SIGNATURE</div>
    </div>

    <div class="line"></div>
    <span
      >Payment Instructions are revocable by request provided the beneficiary
      has not already been advised or credited.</span
    >
  </div>
</mat-card>
