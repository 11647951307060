import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/services/auth.service';
import { first } from 'rxjs/operators';
import { Program } from '../login/models/program.enum';
import { Sections } from './shared/sections.enum';
import { DashboardService } from './dashboard.service';
import { currencies } from './shared/currencies';
import { WalletInstance } from './models/walletInstance';
import { Card } from './models/card.model';
import { AccountStatus } from './shared/account-status.enum';
import { ErrorService } from '../shared/error-dialog/error.service';
import { calculateDateRange } from './shared/calculateDateRange';
import { StatementItem } from '../statement/models/statement-item.model';
import { StatementService } from '../statement/statement.service';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  program?: Program;
  Sections = Sections;
  isLoadingIBAN = false;
  isLoadingCards = false;
  isLoadingIBANTransactions = false;
  isLoadingCardTransactions = false;
  isLoadingWallet = false;
  accountNumber?: string;
  availableIbanBalance?: number;
  ibanBalance?: number;
  wallet: WalletInstance = {};
  cards: Card[] = [];
  currentCard?: Card;
  ibanTransactions?: StatementItem[];
  cardTransactions?: StatementItem[];
  AccountStatus = AccountStatus;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private errorService: ErrorService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.cards = [];
    this.isLoadingIBAN = true;
    this.isLoadingCards = true;
    this.isLoadingWallet = true;
    const dateRange = calculateDateRange();

    currencies.forEach((currency) => {
      this.wallet[currency.short] = {
        availableBalance: null,
        balance: null,
        isLoading: true,
      };
    });

    // gets user program
    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe(
        (user) => {
          this.program = user?.program;
        },
        (error) => {
          this.errorService.showErrorDialog(error.error.message);
        }
      );

    // gets accounts
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        if (accounts.iban) {
          this.accountNumber = accounts.iban.iban;
          this.availableIbanBalance = accounts.iban.availableBalance;
          this.ibanBalance = accounts.iban.balance;
        }

        if (accounts.wallets) {
          currencies.map((currency) => {
            const acc = accounts.wallets.find((w) => w.ccy === currency.short);
            if (acc !== undefined) {
              this.wallet[currency.short].availableBalance =
                acc.availableBalance;
              this.wallet[currency.short].balance = acc.balance;
            }
            this.wallet[currency.short].isLoading = false;
          });
        }

        this.isLoadingIBAN = false;
        this.isLoadingCards = false;
        this.isLoadingWallet = false;

        // TODO: get cards and statements for Card
        // const allCards = accounts.cards.map((card) =>
        //   this.dashboardService.getAccountInfo(card)
        // );
        // if (allCards.length > 0) {
        //   forkJoin(allCards).subscribe(
        //     (cardsInfo: AccountInfo[]) => {
        //       this.cards = cardsInfo
        //         .map<Card | null>(({ cardHolder, cardInfo }, index) =>
        //           this.isDeactivated(cardInfo.cardStatus)
        //             ? null
        //             : {
        //                 cardholder: `${cardHolder.firstName} ${cardHolder.lastName}`,
        //                 balance: +cardInfo.availBal,
        //                 status: cardInfo.cardStatus,
        //                 name: `Card - ${cardHolder.cardNumber.slice(-4)}`,
        //                 expireDate: cardInfo.expirationDate,
        //                 numAccountID: accounts.cards[index].numId,
        //               }
        //         )
        //         .filter((card): card is Card => !!card);
        //       this.isLoadingCards = false;
        //       this.setCurrentCard(this.cards[0]);
        //     },
        //     (error) => {
        //       this.isLoadingCards = false;
        //       this.errorService.showErrorDialog();
        //     }
        //   );
        // } else {
        //   this.isLoadingCards = false;
        // }
        // get latest transactions of iban
        this.isLoadingIBANTransactions = true;
        this.statementService
          .getStatement({
            accountCode: accounts?.iban?.code,
            dtStartDate: dateRange.past,
            dtEndDate: dateRange.present,
            page: 0,
            limit: 6,
            orderAscending: false,
            currency: accounts?.iban?.ccy,
          })
          .subscribe(
            (statements) => {
              this.ibanTransactions = statements.items;
              this.isLoadingIBANTransactions = false;
            },
            () => {
              this.isLoadingIBANTransactions = false;
              this.errorService.showErrorDialog();
            }
          );
      },
      () => {
        currencies.map((currency) => {
          this.wallet[currency.short].isLoading = false;
        });
        this.isLoadingIBAN = false;
        this.isLoadingCards = false;
        this.isLoadingWallet = false;
        this.isLoadingIBANTransactions = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  setCurrentCard(card?: Card): void {
    if (!card) {
      return;
    }
    const dateRange = calculateDateRange();
    this.isLoadingCardTransactions = true;
    this.cardTransactions = undefined;
    this.currentCard = card;
    // this.dashboardService
    //   .getAccountStatements(card.numAccountID, {
    //     limit: 6,
    //     dtStartDate: dateRange.past,
    //     dtEndDate: dateRange.present,
    //   })
    //   .subscribe(
    //     (statements) => {
    //       this.cardTransactions = statements.items;
    //       this.isLoadingCardTransactions = false;
    //     },
    //     (error) => {
    //       this.isLoadingCardTransactions = false;
    //       if (error.status === 400) return;

    //       this.errorService.showErrorDialog();
    //     }
    //   );
  }

  // private isDeactivated(cardStatus: AccountStatus): boolean {
  //   return (
  //     cardStatus === AccountStatus.EXPIRED ||
  //     cardStatus === AccountStatus.CLOSED
  //   );
  // }
}
