import { TitleCasePipe } from '@angular/common';
import { Country } from 'src/app/shared/models/country.model';
import { UserState } from 'src/app/login/models/user-state.enum';
import { AppDocumentType } from 'src/app/onboarding-new/models/document-type.enum';
import { MonthlyIncomeNew } from 'src/app/onboarding-new/models/monthly-income.enum';
import { MonthlySpend } from 'src/app/onboarding-new/models/monthly-spend.enum';
import { SizeOfWealth } from 'src/app/onboarding-new/models/size-of-wealth.enum';
import { FormGroup, Validators } from '@angular/forms';
import { AmlMatchResult } from 'src/app/onboarding-new/models/aml-match-result.enum';
import { FavouriteTransfer } from 'src/app/transfer/shared/models/favourite-transfer.model';
import { Beneficiary } from 'src/app/transfer/shared/models/beneficiary.model';

export const NA = 'N/A';
export const descriptionMaxLength = 140;

const documentNamesMap = new Map<AppDocumentType, string>([
  [AppDocumentType.IDENTITY_CARD, '1st page of the ID card'],
  [AppDocumentType.PASSPORT, 'Passport'],
  [AppDocumentType.PROOF_OF_ADDRESS, 'Proof of Address'],
  [
    AppDocumentType.CERTIFICATE_OF_INCORPORATION,
    'Certificate of Incorporation',
  ],
  [
    AppDocumentType.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
    'Memorandum and Articles of Association',
  ],
  [
    AppDocumentType.LATEST_FINANCIAL_STATEMENTS,
    `Latest Audited Financial Statements of the last 2 (two) years or if not available, the Last Financial Statements available or Draft Account signed by the company's directors`,
  ],
  [AppDocumentType.CERTIFICATE_OF_SHAREHOLDERS, 'Certificate of Shareholders'],
  [AppDocumentType.CERTIFICATE_OF_DIRECTORS, 'Certificate of Directors'],
  [AppDocumentType.ANOTHER_FILE, 'Additional document'],
  [AppDocumentType.IDENTITY_CARD_SECOND_PAGE, '2nd page of the ID card'],
  [
    AppDocumentType.LETTER_OF_RECOMMENDATION,
    'Letter of recommendation from a third party (i.e. reputable financial institution or EU professional) confirming professional reputation and integrity',
  ],
  [AppDocumentType.SOURCE_OF_WEALTH, 'Source of Wealth (accumulated assets)'],
  [AppDocumentType.ORIGIN_OF_FUNDS, 'Source of Incoming Funds'],
  [
    AppDocumentType.DESTINATION_OF_OUTWARD_PAYMENTS,
    'Destination of Outward Payments',
  ],
  [AppDocumentType.PROOF_OF_OCCUPATION_STATUS, 'Proof of Occupation Status'],
  [
    AppDocumentType.CERTIFICATE_OF_REGISTERED_OFFICE,
    'Certificate of Registered Office',
  ],
  [
    AppDocumentType.BOARD_RESOLUTION,
    'Board resolution indicating the signatories of the company',
  ],
  [AppDocumentType.LICENSE, 'License (optional)'],
  [AppDocumentType.FACE, 'Face'],
]);

export function getDocumentName(documentType: AppDocumentType): string {
  return documentNamesMap.get(documentType) as string;
}

// show only descriptions that are not self-explanatory
export const amlMatchResultDescriptions = new Map<AmlMatchResult, string>([
  [
    AmlMatchResult.AKA_EXACT,
    'Matched against an entity AKA (also known as) entry exactly',
  ],
  [
    AmlMatchResult.NAME_FUZZY,
    'Matched closely to the name, but at least one word had an edit distance change',
  ],
  [
    AmlMatchResult.AKA_FUZZY,
    'Matched closely to an AKA name, but at least one word had an edit distance change',
  ],
  [
    AmlMatchResult.PHONETIC_NAME,
    'Matched against the entity name phonetically',
  ],
  [AmlMatchResult.PHONETIC_AKA, 'Matched against an entity AKA phonetically'],
  [
    AmlMatchResult.EQUIVALENT_NAME,
    'Matched against the entity name with a synonym',
  ],
  [
    AmlMatchResult.EQUIVALENT_AKA,
    'Matched against an entity AKA with a synonym',
  ],
  [
    AmlMatchResult.UNKNOWN,
    'Matched for a more complex reason, such as based on an acronym',
  ],
  [AmlMatchResult.YEAR_OF_BIRTH, 'Matched the birth year as given'],
]);

export function getRelevanceDescription(matchType?: any) {
  if (Object.values(AmlMatchResult).includes(matchType as AmlMatchResult)) {
    return (amlMatchResultDescriptions.get(matchType) as string)
      ? `(${amlMatchResultDescriptions.get(matchType) as string})`
      : '';
  }
  return '';
}

export function getIdentityDocumentsTypesSet() {
  return new Set<AppDocumentType>([
    AppDocumentType.PASSPORT,
    AppDocumentType.IDENTITY_CARD,
    AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
  ]);
}

export const acceptedDocumentTypes = [
  AppDocumentType.PASSPORT,
  AppDocumentType.IDENTITY_CARD,
  AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
  AppDocumentType.PROOF_OF_ADDRESS,
  AppDocumentType.FACE,
];

export const COUNTDOWN_IN_SECONDS = 60;
export const NUMBER_OF_ALLOWED_SMS_RESENDS = 5;

// converts UserState to readable value
export function convertUserState(state: UserState): string {
  return UserState[state].replace(/_/g, ' ');
}

// converts countryId to country name (in titlecase)
export function getCountryById(countries: Country[], id?: number): string {
  const countryName = id
    ? countries.find((country) => country.id === id)?.name ?? ''
    : '';
  return new TitleCasePipe().transform(countryName);
}

// converts country code to country name (in titlecase)
export function getCountryByCode(countries: Country[], code?: string): string {
  const countryName = code
    ? countries.find((country) => country.code === code)?.name ?? ''
    : '';
  return new TitleCasePipe().transform(countryName);
}

// converts countryId to nationality (in titlecase)
export function getNationalityById(countries: Country[], id?: number): string {
  const nationalityName = id
    ? countries.find((country) => country.id === id)?.nationality ?? ''
    : '';
  return new TitleCasePipe().transform(nationalityName);
}

// converts monthly income to user friendly string
export function getMonthlyIncomeNew(val?: string): string {
  const entryFound = Object.entries(MonthlyIncomeNew).find(
    (entry) => entry[1] === val
  );
  return entryFound ? entryFound[0] : '';
}

// converts monthly spend to user friendly string
export function getMonthlySpend(val?: string): string {
  const entryFound = Object.entries(MonthlySpend).find(
    (entry) => entry[1] === val
  );
  return entryFound ? entryFound[0] : '';
}

// converts size of wealth to user friendly string
export function getSizeOfWealth(val?: string): string {
  const entryFound = Object.entries(SizeOfWealth).find(
    (entry) => entry[1] === val
  );
  return entryFound ? entryFound[0] : '';
}

export function getStringValue(value?: boolean) {
  return value === true ? 'Yes' : value === false ? 'No' : '-';
}

export function getScoreValue(value?: number) {
  return value === null ? '-' : value;
}

export function getFoundValue(value?: boolean) {
  return value === true ? 'Found' : value === false ? 'Not Found' : '-';
}

export function setExtraQuestionsToRequired(form: FormGroup) {
  form.get('dataSourceOfWealth')?.setValidators(Validators.required);
  form.get('dataSourceOfIncome')?.setValidators(Validators.required);
  form.get('dataSizeOfWealth')?.setValidators(Validators.required);
  form.get('countryOfSourceOfWealth')?.setValidators(Validators.required);
  form.get('countryOfSourceOfIncome')?.setValidators(Validators.required);
}

export function setEddQuestionsToRequired(form: FormGroup) {
  form
    .get('dataTaxNumber')
    ?.setValidators([
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
      Validators.maxLength(50),
    ]);
}

export function convertIban(iban: string): string {
  return iban.toString().replace(/\w{4}(?=.)/g, '$& ');
}

export function isHigh(score: number | string | undefined) {
  return Number(score) === 5; // red alert if score is high
}

export const atLeastOneCheckboxIsChecked = () => {
  return (group: FormGroup) => {
    if (
      group.value?.isAuthorizedPerson === false &&
      group.value?.isDirector === false &&
      group.value?.isUBO === false &&
      group.value?.isShareholder === false &&
      group.touched
    ) {
      return { atLeastOneCheckboxChecked: true };
    } else return null;
  };
};

export const atLeastOneCheckboxIsCheckedCounterparty = () => {
  return (group: FormGroup) => {
    if (
      group.value?.isIncomingTransaction === false &&
      group.value?.isOutgoingTransaction === false
    ) {
      return { atLeastOneCheckboxCheckedCounterparty: true };
    } else return null;
  };
};

export const percentageValidators = [
  Validators.max(100),
  Validators.min(0.01),
  Validators.pattern('^[0-9]*(.)?[0-9]{0,2}$'),
];

export function getCurrentUtcDateZero() {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0); // set hours, minutes, seconds, milliseconds to 0
  return date.toISOString();
}

export function extractTermsFromFavourite(
  favouriteTransfer: FavouriteTransfer
) {
  return `${favouriteTransfer.name} ${favouriteTransfer.benName} ${favouriteTransfer.benAccNo}`;
}

export function extractTermsFromBeneficiary(beneficiary: Beneficiary) {
  return `${beneficiary.name} ${beneficiary.accountNo} ${beneficiary.bank} ${beneficiary.bic}`;
}

export function getJurisdictionName(jurisdictionCode: string): string {
  return jurisdictionCodesMap.get(jurisdictionCode) || jurisdictionCode;
}

export const jurisdictionCodesMap = new Map<string, string>([
  ['ae_az', 'Abu Dhabi (UAE)'],
  ['us_al', 'Alabama (US)'],
  ['us_ak', 'Alaska (US)'],
  ['al', 'Albania'],
  ['us_az', 'Arizona (US)'],
  ['us_ar', 'Arkansas (US)'],
  ['aw', 'Aruba'],
  ['au', 'Australia'],
  ['bs', 'Bahamas'],
  ['bh', 'Bahrain'],
  ['bd', 'Bangladesh'],
  ['bb', 'Barbados'],
  ['by', 'Belarus'],
  ['be', 'Belgium'],
  ['bz', 'Belize'],
  ['bm', 'Bermuda'],
  ['bo', 'Bolivia'],
  ['br', 'Brazil'],
  ['bg', 'Bulgaria'],
  ['us_ca', 'California (US)'],
  ['kh', 'Cambodia'],
  ['ca', 'Canada'],
  ['us_co', 'Colorado (US)'],
  ['us_ct', 'Connecticut (US)'],
  ['hr', 'Croatia'],
  ['cw', 'Curaçao'],
  ['cy', 'Cyprus'],
  ['us_de', 'Delaware (US)'],
  ['dk', 'Denmark'],
  ['us_dc', 'District of Columbia (US)'],
  ['do', 'Dominican Republic'],
  ['ae_du', 'Dubai (UAE)'],
  ['fi', 'Finland'],
  ['us_fl', 'Florida (US)'],
  ['fr', 'France'],
  ['gf', 'French Guiana'],
  ['us_ga', 'Georgia (US)'],
  ['de', 'Germany'],
  ['gi', 'Gibraltar'],
  ['gr', 'Greece'],
  ['gl', 'Greenland'],
  ['gp', 'Guadeloupe'],
  ['gg', 'Guernsey'],
  ['us_hi', 'Hawaii (US)'],
  ['hk', 'Hong Kong'],
  ['is', 'Iceland'],
  ['us_id', 'Idaho (US)'],
  ['in', 'India'],
  ['us_in', 'Indiana (US)'],
  ['us_ia', 'Iowa (US)'],
  ['ir', 'Iran'],
  ['ie', 'Ireland'],
  ['im', 'Isle of Man'],
  ['il', 'Israel'],
  ['jm', 'Jamaica'],
  ['jp', 'Japan'],
  ['je', 'Jersey'],
  ['us_ks', 'Kansas (US)'],
  ['us_ky', 'Kentucky (US)'],
  ['lv', 'Latvia'],
  ['li', 'Liechtenstein'],
  ['us_la', 'Louisiana (US)'],
  ['lu', 'Luxembourg'],
  ['us_me', 'Maine (US)'],
  ['my', 'Malaysia'],
  ['mt', 'Malta'],
  ['mq', 'Martinique'],
  ['us_md', 'Maryland (US)'],
  ['us_ma', 'Massachusetts (US)'],
  ['mu', 'Mauritius'],
  ['yt', 'Mayotte'],
  ['mx', 'Mexico'],
  ['us_mi', 'Michigan (US)'],
  ['us_mn', 'Minnesota (US)'],
  ['us_ms', 'Mississippi (US)'],
  ['us_mo', 'Missouri (US)'],
  ['md', 'Moldova'],
  ['us_mt', 'Montana (US)'],
  ['me', 'Montenegro'],
  ['mm', 'Myanmar'],
  ['us_ne', 'Nebraska (US)'],
  ['nl', 'Netherlands'],
  ['us_nv', 'Nevada (US)'],
  ['ca_nb', 'New Brunswick (Canada)'],
  ['us_nh', 'New Hampshire (US)'],
  ['us_nj', 'New Jersey (US)'],
  ['us_nm', 'New Mexico (US)'],
  ['us_ny', 'New York (US)'],
  ['nz', 'New Zealand'],
  ['ca_nl', 'Newfoundland and Labrador (Canada)'],
  ['us_nc', 'North Carolina (US)'],
  ['us_nd', 'North Dakota'],
  ['no', 'Norway'],
  ['ca_ns', 'Nova Scotia (Canada)'],
  ['us_oh', 'Ohio (US)'],
  ['us_ok', 'Oklahoma (US)'],
  ['us_or', 'Oregon (US)'],
  ['pk', 'Pakistan'],
  ['pa', 'Panama'],
  ['us_pa', 'Pennsylvania (US)'],
  ['pl', 'Poland'],
  ['ca_pe', 'Prince Edward Island (Canada)'],
  ['pr', 'Puerto Rico'],
  ['ca_qc', 'Quebec (Canada)'],
  ['us_ri', 'Rhode Island (US)'],
  ['ro', 'Romania'],
  ['rw', 'Rwanda'],
  ['re', 'Réunion'],
  ['bl', 'Saint Barthélemy'],
  ['mf', 'Saint Martin (French part)'],
  ['pm', 'Saint Pierre and Miquelon'],
  ['sg', 'Singapore'],
  ['sk', 'Slovakia'],
  ['si', 'Slovenia'],
  ['za', 'South Africa'],
  ['us_sc', 'South Carolina (US)'],
  ['us_sd', 'South Dakota (US)'],
  ['es', 'Spain'],
  ['se', 'Sweden'],
  ['ch', 'Switzerland'],
  ['tj', 'Tajikistan'],
  ['tz', 'Tanzania'],
  ['us_tn', 'Tennessee (US)'],
  ['us_tx', 'Texas'],
  ['th', 'Thailand'],
  ['to', 'Tonga'],
  ['tn', 'Tunisia'],
  ['ug', 'Uganda'],
  ['ua', 'Ukraine'],
  ['gb', 'United Kingdom'],
  ['us_ut', 'Utah (US)'],
  ['vu', 'Vanuatu'],
  ['us_vt', 'Vermont (US)'],
  ['vn', 'Viet Nam'],
  ['us_va', 'Virginia (US)'],
  ['us_wa', 'Washington (US)'],
  ['us_wv', 'West Virginia (US)'],
  ['us_wi', 'Wisconsin (US)'],
  ['us_wy', 'Wyoming (US)'],
]);
