import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../admin/users/models/page.model';
import { PaymentItem } from '../statement/models/payment-item.model';
import { StatementSearch } from '../statement/models/statement-search.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  financialUrl = `${environment.BACKEND_URL}/financial`;

  constructor(private http: HttpClient) {}

  getPayments(data: StatementSearch): Observable<Page<PaymentItem>> {
    const url = `${this.financialUrl}/account-payments`;
    return this.http.post<Page<PaymentItem>>(url, data);
  }
}
