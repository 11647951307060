<div class="section-container">
  <div fxLayoutAlign="space-between" fxLayoutGap="10px">
    <span>{{ transaction?.createdAt | date: 'shortDate' }}</span>
    <span class="text-overflow"
      >Reference No. {{ transaction?.transactionRefId }}</span
    >
  </div>
  <div fxLayoutAlign="space-between" fxLayoutGap="10px" class="bolded">
    <div class="text-overflow">
      <span>From/To: {{ transaction?.toAccountName }} </span>
    </div>
    <div
      [ngClass]="
        transaction?.typeName === 'Credit'
          ? 'credit amount'
          : 'debit text-overflow amount'
      "
    >
      <span>
        <ng-container *ngIf="transaction?.typeName === 'Credit'"
          >+</ng-container
        >
        {{ transaction?.fromAmount | amount }} {{ transaction?.fromCCY }}
      </span>
    </div>
  </div>
  <div fxLayoutAlign="space-between" fxLayoutGap="10px">
    <div class="text-overflow">
      <span>{{ transaction?.transactionCodeName }}</span>
    </div>
    <div class="text-overflow">
      <span class="no-wrap"
        >Balance: {{ transaction?.currentBalance | amount }}
        {{ transaction?.currentBalanceCcy }}</span
      >
    </div>
  </div>
</div>
<mat-divider></mat-divider>
