<div class="accounts-container">
  <div
    *ngIf="!isFinishedLoading() && !currentAccountTable"
    fxLayoutAlign="center center"
    class="loader-container"
  >
    <app-big-loader-not-centered></app-big-loader-not-centered>
  </div>
  <app-accounts-table
    *ngIf="!currentAccountTable"
    [matTableDataSource]="accountsTable"
    [isFinishedLoading]="isFinishedLoading()"
    (currentAccountTableEmitter)="changeCurrentAccountTable($event)"
  ></app-accounts-table>
  <app-generic-accounts-table
    *ngIf="currentAccountTable"
    [userId]="userId"
    [accountTable]="currentAccountTable"
    (resetCurrentTable)="resetCurrentTable()"
  ></app-generic-accounts-table>
</div>
